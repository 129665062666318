<template>
	<div>
		<!-- 内容 -->
		<div class="ordermanagebox">
			<el-card>
				<div class="global-search-box">
					<div class="search-content">
						<div class="search-item">
							<label class="search-label">开票状态：</label>
							<el-select v-model="valuename" placeholder="选择开票状态">
								<el-option v-for="(item, index) in invoiscetypelist" :key="item.value"
									:label="item.label" :value="item.value"></el-option>
							</el-select>
						</div>
						<div class="search-item">
							<label class="search-label">邮寄状态：</label>
							<el-select v-model="valuesend" placeholder="选择邮寄状态">
								<el-option v-for="(item, index) in invoiscemaillist" :key="item.value"
									:label="item.label" :value="item.value"></el-option>
							</el-select>
						</div>
						<div class="search-item">
							<label class="search-label">来源：</label>
							<el-select v-model="fpsource" filterable clearable placeholder="选择发票来源">
								<el-option v-for="item in  common_info['order.source.type.list']" :key="item.key"
									:label="item.value" :value="item.key">
								</el-option>
							</el-select>
						</div>
						<div class="search-item">
							<label class="search-label">订单编号：</label>
							<el-input  v-model="orderinvoicetext" placeholder="请输入订单编号"></el-input>
						</div>
						<div class="search-item">
							<el-input v-model="invoicename" :placeholder="valuerise == 1 ? '请输入发票抬头' : '请输入企业税号'">
								<template slot="prepend">
									<el-select v-model="valuerise">
										<el-option v-for="(item, index) in invoiscelisted" :key="item.value"
											:label="item.label" :value="item.value"></el-option>
									</el-select>
								</template>
							</el-input>
						</div>
						<div class="search-item">
							<label class="search-label">时间筛选：</label>
							<el-date-picker v-model="valuetimes" type="datetimerange" :picker-options="pickerOptions" start-placeholder="开始日期" format="yyyy-MM-dd" end-placeholder="结束日期" :default-time="['00:00:00', '23:59:59']">
							</el-date-picker>
						</div>
					</div>
					<div class="serach-buttom-button">
					    <el-button type="primary" @click="invoiceseach">搜索</el-button>
					    <el-button @click="resetting">重置条件</el-button>
					</div>
				</div>
			</el-card>

			<div class="ordermanageboxbut">
				<div class="boxtableconter">
					<div class="boxtable">
						<el-table :data="tableData" style="width: 100%">
							<el-table-column label="订单号" width="240">
								<template slot-scope="scope">
									{{ scope.row.order_no }}
								</template>
							</el-table-column>
							<el-table-column label="订单金额" prop="selling" width="140">
								<template slot-scope="scope">
									{{ scope.row.order?.total_price }}
								</template>
							</el-table-column>
							<el-table-column label="所属用户" prop="invoiceuser" width="180">
								<template slot-scope="scope">
									{{ scope.row.order?.purchaser_name }}
								</template>
							</el-table-column>
							<el-table-column label="发票抬头" prop="invoicerise" width="240">
								<template slot-scope="scope">
									{{ scope.row.header }}
								</template>
							</el-table-column>
							<el-table-column label="发票来源" prop="invoicesource" width="150">
								<template slot-scope="scope">
									{{ common_info['order.source.type.map'][scope.row.source] }}
								</template>
							</el-table-column>
							<el-table-column label="发票类型" width="180">
								<template slot-scope="scope">
									{{ common_info['invoice.type.map'][scope.row.head_type] }}
								</template>
							</el-table-column>
							<el-table-column label="状态 " width="150">
								<template slot-scope="scope">
									{{ common_info['invoice.status.map'][scope.row.status] }}
								</template>
							</el-table-column>
							<el-table-column label="发票邮寄" prop="invoicesend" width="140">
								<template slot-scope="scope">
									{{ common_info['invoice.send.list.map'][scope.row.is_send] }}
								</template>
							</el-table-column>
							<el-table-column label="发票号" prop="invoicenum" width="200">
								<template slot-scope="scope">
									{{ scope.row.invoice_no ? scope.row.invoice_no : '-' }}
								</template>
							</el-table-column>
							<el-table-column fixed="right" width="200" style="text-align: center;">
								<template slot="header" slot-scope="scope">
									<div class="slotsetoperate">
										操作
										<!-- <i class="el-icon-setting" @click="laborslot"></i> -->
									</div>
								</template>
								<template slot-scope="scope">
									<div class="setoperate">
										<button href="order_invoicedetail.html" @click="loopinvoice(scope.row.id)"
											v-if="hasPerm(['order.invoice.show'])">查看</button>
										<button href="javascript:void(0);" @click="invoicenum(scope.row)"
											v-if="hasPerm(['order.invoice.code'])">发票号</button>
										<!-- <button href="javascript:void(0);">诺诺发票</button> -->
										<button href="javascript:void(0);" @click="upimginvoices(scope.row)"
											v-if="hasPerm(['order.invoice.upload'])">上传发票</button>
										<div v-if="scope.row.source != 11 && scope.row.source != 13">
											<button href="javascript:void(0);" @click="invoicelogistics(scope.row)"
												v-if="scope.row.is_send == 1 && hasPerm(['order.invoice.express'])">输入物流信息</button>
										</div>
									</div>
								</template>
							</el-table-column>
						</el-table>
					</div>
					<div class="tablepage d-flex justify-content-center">
						<div class="orderlastbutright">
							<el-pagination @size-change="handleSizeChange" hide-on-single-page
								@current-change="handleCurrentChange" :current-page.sync="currentPage"
								:page-sizes="[10, 20, 30, 40]" :page-size="eachPage"
								layout="sizes, prev, pager, next, jumper" :pager-count="5" :total="total_entry">
							</el-pagination>
						</div>
					</div>
				</div>
			</div>
		</div>

		<!-- 发票号 -->
		<el-dialog title="发票号" class="indetailtc" :visible.sync="openinvotype">
			<div class="indetailbox">
				<div class="openinvoinp">
					<input type="text" placeholder="请输入您的发票号" v-model="incoicenumbers">
				</div>
				<div class="khinvlister">
					<h4>订单信息</h4>
					<ul>
						<li class="clearfix d-flex in_order_no">
							<span>订单号：</span>
							<p>{{ invoicenumcon.order_no }}</p>
						</li>
						<li class="clearfix d-flex in_pay_price">
							<span>订单金额：</span>
							<p>￥{{ invoicenumcon.order?.total_price }}</p>
						</li>
					</ul>
				</div>
				<div class="khinvlister">
					<h4>发票信息</h4>
					<ul>
						<li class="clearfix d-flex in_head_type">
							<span>发票类型：</span>
							<p>{{common_info['invoice.type.map'] && common_info['invoice.type.map'][invoicenumcon.head_type] }}</p>
						</li>
						<li class="clearfix d-flex in_header">
							<span>发票抬头：</span>
							<p>{{ invoicenumcon.header }}</p>
						</li>
						<li class="clearfix d-flex in_header">
							<span>纳税人识别码：</span>
							<p>{{ invoicenumcon.invoice_content?.taxpayer }}</p>
						</li>
						<li class="clearfix d-flex in_header">
							<span>开户行：</span>
							<p>{{ invoicenumcon.invoice_content?.bank_num }}</p>
						</li>
						<li class="clearfix d-flex in_header">
							<span>银行账户：</span>
							<p>{{ invoicenumcon.invoice_content?.bank_account }}</p>
						</li>
						<li class="clearfix d-flex in_header">
							<span>注册电话：</span>
							<p>{{ invoicenumcon.invoice_content?.register_tel }}</p>
						</li>
						<li class="clearfix d-flex in_header">
							<span>注册地址：</span>
							<p>{{ invoicenumcon.invoice_content?.register_address }}</p>
						</li>
					</ul>
				</div>
			</div>
			<div slot="footer" class="dialog-footer">
				<el-button @click="openinvotype = false">关闭</el-button>
				<el-button @click="openinvnumed(invoicenumcon.id)" class="addressqr">确 定</el-button>
			</div>
		</el-dialog>

		<!-- 物流信息 -->
		<el-dialog title="物流信息" class="logisticstc" :visible.sync="logisticstype">
			<div class="logisticsbox">
				<div class="deliveryboxbut">
					<ul class="clearfloat">
						<li class="clearfloat">
							<span class="deliverytexted">物流选择：</span>
							<div class="deliveryright">
								<template>
									<el-radio v-model="radio"
										v-for="(item, index) in common_info['invoice.express.type.list']" :key="index"
										:label="item.key">{{ item.value }}</el-radio>
								</template>
							</div>
						</li>
						<template v-if="radio == 1">
							<li class="clearfloat">
								<span class="deliverytexted">发货方式：</span>
								<div class="deliveryright">
									<template>
										<el-radio v-model="radio1" v-for="(item, index) in common_info['invoice.send.list']" :key="index"
											:label="item.key">{{ item.value }}</el-radio>
									</template>
								</div>
							</li>
							<li class="clearfloat deliveryts">
								<span class="deliverytexted">快递公司：</span>
								<div class="deliveryright deliveryrighted clearfloat">
									<template>
										<el-select v-model="demail" filterable placeholder="请选择">
											<el-option v-for="(item, index) in common_info['express.delivery.list']"
												:key="index" :label="item.value" :value="item.value"></el-option>
										</el-select>
									</template>
								</div>
							</li>
							<li class="clearfloat deliveryts">
								<span class="deliverytexted">快递单号：</span>
								<div class="deliveryright  clearfloat">
									<input type="text" v-model="logisticstext" placeholder="请输入快递单号" class="invoiceinput">
								</div>
							</li>
							<li class="clearfloat">
								<span class="deliverytexted">商家留言：</span>
								<div class="deliveryright">
									<textarea placeholder="输入商家留言(选填)" v-model="invoicely"></textarea>
								</div>
							</li>
						</template>

					</ul>
				</div>
			</div>
			<div slot="footer" class="dialog-footer">
				<el-button @click="logisticstype = false">取 消</el-button>
				<el-button @click="inconlogist()" class="addressqr">确 定</el-button>
			</div>
		</el-dialog>

		<!-- 操作弹层 -->
		<el-dialog title="选择表头显示内容" class="labortc" :visible.sync="labortype">
			<div class="laborbox">
				<div class="laborconter clearfloat">
					<div class="laborleft">
						<h4 class="labortitled">可选属性<span>（24）</span></h4>
						<div class="laborseach">
							<i class="el-icon-search"></i>
							<input type="text" placeholder="搜索可选属性">
						</div>
						<div class="laborlist clearfloat">
							<el-checkbox class="cgcheckbox" disabled>订单号</el-checkbox>
							<el-checkbox class="cgcheckbox" disabled>订单金额</el-checkbox>
							<el-checkbox class="cgcheckbox">所属用户</el-checkbox>
							<el-checkbox class="cgcheckbox">发票抬头</el-checkbox>
							<el-checkbox class="cgcheckbox">发票来源</el-checkbox>
							<el-checkbox class="cgcheckbox">发票类型</el-checkbox>
							<el-checkbox class="cgcheckbox">状态</el-checkbox>
							<el-checkbox class="cgcheckbox">发票邮寄</el-checkbox>
							<el-checkbox class="cgcheckbox">发票号</el-checkbox>
							<el-checkbox class="cgcheckbox">电子票</el-checkbox>
						</div>
					</div>
					<div class="laboright">
						<h4 class="labortitled">可选属性<span>（24）</span></h4>
						<div class="laborightlist">
							<ul id="laborlist">
								<li class="clearfloat">
									<div class="lablistleft">
										<i class="el-icon-s-unfold"></i>
										<span>订单号</span>
									</div>
									<div class="lablistright clearfloat">
										<a href="javascript:void(0);" class="sticky el-icon-top"></a>
										<a href="javascript:void(0);" class="cgdelect el-icon-remove-outline"></a>
									</div>
								</li>
								<li class="clearfloat">
									<div class="lablistleft">
										<i class="el-icon-s-unfold"></i>
										<span>订单金额</span>
									</div>
									<div class="lablistright clearfloat">
										<a href="javascript:void(0);" class="sticky el-icon-top"></a>
										<a href="javascript:void(0);" class="cgdelect el-icon-remove-outline"></a>
									</div>
								</li>
								<li class="clearfloat">
									<div class="lablistleft">
										<i class="el-icon-s-unfold"></i>
										<span>所属用户</span>
									</div>
									<div class="lablistright clearfloat">
										<a href="javascript:void(0);" class="sticky el-icon-top"></a>
									</div>
								</li>
								<li class="clearfloat">
									<div class="lablistleft">
										<i class="el-icon-s-unfold"></i>
										<span>发票抬头</span>
									</div>
									<div class="lablistright clearfloat">
										<a href="javascript:void(0);" class="sticky el-icon-top"></a>
									</div>
								</li>
								<li class="clearfloat">
									<div class="lablistleft">
										<i class="el-icon-s-unfold"></i>
										<span>发票来源</span>
									</div>
									<div class="lablistright clearfloat">
										<a href="javascript:void(0);" class="sticky el-icon-top"></a>
										<a href="javascript:void(0);" class="cgdelect el-icon-remove-outline"></a>
									</div>
								</li>
								<li class="clearfloat">
									<div class="lablistleft">
										<i class="el-icon-s-unfold"></i>
										<span>发票类型</span>
									</div>
									<div class="lablistright clearfloat">
										<a href="javascript:void(0);" class="sticky el-icon-top"></a>
										<a href="javascript:void(0);" class="cgdelect el-icon-remove-outline"></a>
									</div>
								</li>
								<li class="clearfloat">
									<div class="lablistleft">
										<i class="el-icon-s-unfold"></i>
										<span>发票邮寄</span>
									</div>
									<div class="lablistright clearfloat">
										<a href="javascript:void(0);" class="sticky el-icon-top"></a>
										<a href="javascript:void(0);" class="cgdelect el-icon-remove-outline"></a>
									</div>
								</li>
								<li class="clearfloat">
									<div class="lablistleft">
										<i class="el-icon-s-unfold"></i>
										<span>状态</span>
									</div>
									<div class="lablistright clearfloat">
										<a href="javascript:void(0);" class="sticky el-icon-top"></a>
									</div>
								</li>
								<li class="clearfloat">
									<div class="lablistleft">
										<i class="el-icon-s-unfold"></i>
										<span>发票号</span>
									</div>
									<div class="lablistright clearfloat">
										<a href="javascript:void(0);" class="sticky el-icon-top"></a>
									</div>
								</li>
								<li class="clearfloat">
									<div class="lablistleft">
										<i class="el-icon-s-unfold"></i>
										<span>电子票</span>
									</div>
									<div class="lablistright clearfloat">
										<a href="javascript:void(0);" class="sticky el-icon-top"></a>
										<a href="javascript:void(0);" class="cgdelect el-icon-remove-outline"></a>
									</div>
								</li>
							</ul>
						</div>
					</div>
				</div>
			</div>
			<div slot="footer" class="dialog-footer clearfloat">
				<div class="dialog-footerleft">
					<el-button>恢复默认值</el-button>
				</div>
				<div class="dialog-footeright">
					<el-button @click="labortype = false">取 消</el-button>
					<el-button @click="labortype = false" class="addressqr">确 定</el-button>
				</div>
			</div>
		</el-dialog>
		<!-- 上传发票 -->
		<el-dialog title="上传发票" class="upinvoicetc" :visible.sync="upinvoicetype">
			<div class="upinvoicebox">
				<div class="deliveryboxbut">
					<ul class="clearfloat">
						<li class="clearfloat">
							<span class="deliverytexted">选择发票：</span>
							<div class="deliveryright">
								<template>
									<el-radio v-model="invoiceradio"
										v-for="(item, index) in common_info['invoice.bill.type.list']" :key="index" :label="item.key">{{
											item.value }}</el-radio>
								</template>
							</div>
						</li>
						<li class="clearfloat" v-if="invoiceradio == 0">
							<span class="deliverytexted">纸质发票：</span>
							<div class="clearfloat">
								<div class="deliveimgs clearfloat" v-if="invoiceimglist && invoiceimglist.length">
									<p v-for="(item, index) in invoiceimglist" :key="index">
										<img :src="item.file_url" alt="">
										<span @click="delectimg(index)">删除</span>
									</p>
								</div>
								<div class="upinvoiceimg" @click="photoVisible(1)"><i class="el-icon-plus"></i></div>
							</div>
						</li>
						<li class="clearfloat" v-else>
							<span class="deliverytexted">电子发票：</span>
							<div class="upinvoiceinput clearfloat">
								<div class="clearfloat">
									<input type="text" v-model="elecontext" placeholder="请填写电子票地址 例:https://www.xxx.cn">
									<button @click="addelectron()">添加电子票</button>
								</div>
								<div class="upinvlist clearfloat" v-if="electronlist && electronlist.length">
									<div class="clearfloat upinvlistcon" v-for="(item, index) in electronlist" :key="index">
										<input type="text" :value="item" disabled>
										<strong class="el-icon-delete" @click="delectelectron(index)"></strong>
									</div>
								</div>
							</div>
						</li>
					</ul>
				</div>
			</div>
			<div slot="footer" class="dialog-footer">
				<el-button @click="upinvoicetype = false">取 消</el-button>
				<el-button class="addressqr" @click="invoiceupimg()">确 定</el-button>
			</div>
		</el-dialog>
		<!-- 图片选择器 -->
		<pictureSelect ref="imglist" :multipled="is_multiple" @change="imgbrand($event)"></pictureSelect>
	</div>
</template>

<script>
import Sortable from "sortablejs";
export default {
	data() {
		return {
			is_multiple: 0,
			valuename: '',//开票状态
			valuesend: '',//邮寄状态
			valuerise: '1',//抬头类型/企业税号
			valuetimes: '',//日期选择
			fpsource: '',//发票来源
			orderinvoicetext: '',//订单编号
			invoicename: '',
			currentPage: 1,
			eachPage: 10,
			total_entry: 0,
			tableData: [], //发票列表
			logisticstype: false,//物流信息
			radio: 0,//是否需要物流
			radio1: 1,//发货方式
			demail: '',//快递公司
			openinvotype: false,//发票号
			labortype: false,//控制操作弹层是否显示
			upinvoicetype: false,//控制上传发票弹层是否显示
			invoiceradio: 0,
			invoicenumcon: '',
			incoicenumbers: '',
			logisticstext: '',
			idcon: '',
			invoicely: '',
			invoiceimglist: [],
			electronlist: [],
			elecontext: '',
			invoiscetypelist: [{
				label: '未开票',
				value: '1',
			},
			{
				label: '已开票',
				value: '3',
			}],
			invoiscemaillist: [{
				label: '未邮寄',
				value: '1',
			},
			{
				label: '已邮寄',
				value: '2',
			}],
			invoiscelisted: [{
				label: '发票抬头',
				value: '1',
			},
			{
				label: '企业税号',
				value: '2',
			}],
			pickerOptions: {
				shortcuts: [{
					text: '今天',
					onClick(picker) {
						const end = new Date();
						const start = new Date();
						start.setTime(start.getTime() - 3600 * 1000 * 24);
						picker.$emit('pick', [start, end]);
					}
				}, {
					text: '7天',
					onClick(picker) {
						const end = new Date();
						const start = new Date();
						start.setTime(start.getTime() - 3600 * 1000 * 24 * 7);
						picker.$emit('pick', [start, end]);
					}
				}, {
					text: '30天',
					onClick(picker) {
						const end = new Date();
						const start = new Date();
						start.setTime(start.getTime() - 3600 * 1000 * 24 * 30);
						picker.$emit('pick', [start, end]);
					}
				}]
			},
		};
	},
	components: {
		Sortable
	},
	beforeCreate() {
		this.$store.commit("commonInfo", "");
	},
	created() {
		let data = {
			keys: ["order.source.type.map", "invoice.type.map", "invoice.status.map", "invoice.send.list.map", "order.source.type.list",
				"invoice.express.type.list", "invoice.send.list", "express.delivery.list", "invoice.bill.type.list"]
		}
		this.common.getCommonInfo(this, data);
	},
	computed: {
		common_info: {
			get() {
				let stateuser = {
					'order.source.type.map': [], //发票来源
					'invoice.type.map': [],
					'invoice.status.map': [],
					'invoice.send.list.map': [],
					'order.source.type.list': [],
					'invoice.express.type.list': [],
					'invoice.send.list': [],
					'express.delivery.list': [],
					'invoice.bill.type.list': [],
				}
				let val = this.$store.state.common_info || stateuser;
				console.log(val, 'valval');
				return val
			},
			set() { }
		}
	},
	mounted() {
		this.invoiceapi();
	},
	methods: {
		laborslot() {
			this.labortype = true;
			this.$nextTick(() => {
				this.initSort();
			})
		},
		imgbrand(val) {
			if (this.is_multiple) {
				let imgval = JSON.parse(JSON.stringify(this.invoiceimglist));
				imgval = imgval.concat(val);
				this.invoiceimglist = imgval;
			}
			this.$forceUpdate();
		},
		photoVisible(num) {
			this.is_multiple = num;
			this.$refs.imglist.photoVisible = true;
		},
		delectimg(index) {
			this.invoiceimglist.splice(index, 1);
		},
		upimginvoices(row) {
			this.idcon = row.id;
			this.upinvoicetype = true;
		},
		addelectron() {
			if (!this.elecontext) {
				this.$message({
					showClose: true,
					message: '请填写电子票地址',
					type: 'warning',
					offset: 200,
					duration: 1000
				});
				return false;
			}
			const urlregex = /^(((ht|f)tps?):\/\/)?([^!@#$%^&*?.\s-]([^!@#$%^&*?.\s]{0,63}[^!@#$%^&*?.\s])?\.)+[a-z]{2,6}\/?/.test(this.elecontext);
			if (!urlregex) {
				this.$message({
					showClose: true,
					message: '请填写有效的url链接',
					type: 'warning',
					offset: 200,
					duration: 1000
				});
				return false;
			}
			if (this.electronlist.length >= 6) {
				this.$message({
					showClose: true,
					message: '最多添加6条',
					type: 'warning',
					offset: 200,
					duration: 1000
				});
				return false;
			}
			this.electronlist.push(this.elecontext)
		},
		delectelectron(index) {
			this.electronlist.splice(index, 1);
		},
		invoiceupimg() {
			let file_path = [];
			if (this.invoiceradio == 0) {
				if (!this.invoiceimglist.length) {
					this.$message({
						showClose: true,
						message: '请上传纸质发票',
						type: 'warning',
						offset: 200,
						duration: 1000
					});
					return false;
				}
				for (let i = 0; i < this.invoiceimglist.length; i++) {
					file_path.push(this.invoiceimglist[i].file_url)
				}
			} else {
				if (!this.electronlist.length) {
					this.$message({
						showClose: true,
						message: '请添加电子发票',
						type: 'warning',
						offset: 200,
						duration: 1000
					});
					return false;
				}
				file_path = this.electronlist;
			}
			let data = {
				file_path: file_path,
				bill_type: this.invoiceradio,
			};
			// console.log(data)
			this.$put(this.$apis.invoiceupload+this.idcon, data).then(res => {
				// console.log(res)
				if (res.code == 200) {
					this.$message({
						type: 'success',
						duration: 1500,
						message: '设置成功',
						onClose: () => {
							this.upinvoicetype = false;
							this.invoiceimglist = [];
							this.electronlist = [];
							this.elecontext = '';
							file_path = [];
							this.invoiceapi();
						}
					});
				} else {
					this.common.message(this, res.message);
				}
			}).catch(err => {
				// console.log(err)
				this.common.message(this, err.message);
			});
		},
		invoiceapi() {
			let data = {
				search: [],
				order: [{
					key: "id",
					value: "desc"
				}],
				page: this.currentPage,
				limit: this.eachPage
			};
			if (this.valuename) {
				data.search.push({ "key": "status", "value": this.valuename, "op": "equal" });
			}
			if (this.valuesend) {
				data.search.push({ "key": "is_send", "value": this.valuesend, "op": "equal" });
			}
			if (this.fpsource) {
				data.search.push({ "key": "source", "value": this.fpsource, "op": "equal" });
			}
			if (this.orderinvoicetext) {
				data.search.push({ "key": "order_no", "value": this.orderinvoicetext, "op": "equal" });
			}
			if (this.invoicename) {
				data.search.push({
					"key": this.valuerise == 1 ? 'header' : "taxpayer",
					"value": this.invoicename,
					"op": this.valuerise == 1 ? 'search' : "equal",
				});
			}
			if (this.valuetimes) {
				data.search.push({ "key": "created_at", "value": [this.common.timeStampTurnTime(this.valuetimes[0]), this.common.timeStampTurnTime(this.valuetimes[1])], "op": "between" });
			}
			// console.log(data)
			this.$get(this.$apis.invoicelist, data).then(res => {
				if (res.code == 200) {
					this.tableData = res.data.list;
					this.total_entry = res.data.total;
				} else {
					this.common.message(this, res.message);
				}
			}).catch(err => {
				// console.log(err)
				this.common.message(this, err.message);
			});
		},
		// 发票号
		invoicenum(row) {
			this.invoicenumcon = row;
			this.openinvotype = true;
		},

		openinvnumed(id) {
			if (!this.incoicenumbers) {
				this.$message({
					showClose: true,
					message: '请填写发票号',
					type: 'warning',
					offset: 200,
					duration: 1000
				});
				return false;
			}
			let data = {
				invoice_no: this.incoicenumbers
			};
			// console.log(data)
			this.$put(this.$apis.invoicenumber+id, data).then(res => {
				if (res.code == 200) {
					this.$message({
						type: 'success',
						duration: 1500,
						message: '设置成功',
						onClose: () => {
							this.openinvotype = false;
							this.incoicenumbers = '';
							this.invoiceapi();
						}
					});
				} else {
					this.common.message(this, res.message);
				}
			}).catch(err => {
				// console.log(err)
				this.common.message(this, err.message);
			});
		},
		// 物流信息
		invoicelogistics(row) {
			this.idcon = row.id;
			this.logisticstype = true;
		},
		inconlogist() {
			if (this.radio == 1) {
				if (!this.demail) {
					this.$message({
						showClose: true,
						message: '请选择快递公司',
						type: 'warning',
						offset: 200,
						duration: 1000
					});
					return false;
				}
				if (!this.logisticstext) {
					this.$message({
						showClose: true,
						message: '请填写快递单号',
						type: 'warning',
						offset: 200,
						duration: 1000
					});
					return false;
				}
			}
			let data = {
				send_type: this.radio1,
				express: this.demail,
				express_no: this.logisticstext,
				express_type: this.radio,
				remarks: this.invoicely,
			};
			// console.log(data)
			this.$put(this.$apis.invoicepexpress+this.idcon, data).then(res => {
				if (res.code == 200) {
					this.$message({
						type: 'success',
						duration: 1500,
						message: '设置成功',
						onClose: () => {
							this.logisticstype = false;
							this.demail = '';
							this.logisticstext = '';
							this.invoicely = '';
							this.invoiceapi();
						}
					});
				} else {
					this.common.message(this, res.message);
				}
			}).catch(err => {
				// console.log(err)
				this.common.message(this, err.message);
			});
		},
		// 搜索
		invoiceseach() {
			this.currentPage = 1;
			this.invoiceapi();
		},
		// 重置
		resetting() {
			this.valuename = '';
			this.valuesend = '';
			this.fpsource = '';
			this.orderinvoicetext = '';
			this.invoicename = '';
			this.valuetimes = '';
			this.invoiceapi();
		},

		loopinvoice(id) {
			this.$router.push({
				path: '/order/order_invoicedetail',
				query: {
					id: id
				}
			});
		},
		handleSizeChange(val) {
			this.eachPage = val;
			this.invoiceapi();
		},
		handleCurrentChange(val) {
			this.currentPage = val;
			this.common.backTop();
			this.invoiceapi();
		},
		initSort() {
			const el = document.getElementById("laborlist");
			// 根据具体需求配置options配置项
			var that = this;
			const sortable = new Sortable(el, {
				onEnd: (evt) => { // 监听拖动结束事件
					console.log(evt.oldIndex) // 当前行的被拖拽前的顺序
					console.log(evt.newIndex) // 当前行的被拖拽后的顺序
				}
			})
		},
	}
};
</script>
<style scoped>
@import url("css/order.css");
.ordermanageboxbut{margin-top: 20px;}
.deliveimgs {
	float: left;
}

.deliveimgs p {
	float: left;
	width: 80px;
	height: 80px;
	margin-right: 6px;
	margin-bottom: 6px;
	position: relative;
	overflow: hidden;
}

.deliveimgs p img {
	display: block;
	width: 100%;
	height: 100%;
	object-fit: cover;
}

.deliveimgs p span {
	position: absolute;
	bottom: -40px;
	left: 0;
	width: 100%;
	font-size: 12px;
	color: #fff;
	background: rgba(0, 0, 0, 0.5);
	line-height: 20px;
	text-align: center;
	cursor: pointer;
	transition: all 0.3s;
}

.deliveimgs p:hover span {
	bottom: 0;
}

.upinvoiceimg {
	float: left;
}

.setoperate {
	width: 100%;
	text-align: center;
}

.setoperate button {
	display: inline-block;
	font-size: 14px;
	color: var(--fontColor, #fb6638);
	position: relative;
	margin-right: 6px;
	background: transparent;
	border: 0;
	cursor: pointer;
}

.setoperate button:last-child {
	margin-right: 0;
}

.setoperate button::before {
	content: "";
	width: 1px;
	height: 12px;
	background: var(--fontColor, #fb6638);
	position: absolute;
	top: 4px;
	right: -3px;
}

.setoperate button:last-child::before {
	display: none;
}

.dialog-footer .addressqr.el-button--default:focus:not(.el-button--primary),
.addressqr.el-button--default:hover:not(.el-button--primary) {
	color: #fff !important;
	background: var(--fontColor, #fb6638) !important;
}
</style>
<style lang="scss" scoped>
	::v-deep .orderfirstleft .el-input__inner{height: 38px;line-height: 38px;}
	::v-deep .ordertimeinput .el-range-editor.el-input__inner{height: 38px;}
	.boxtable{
		/deep/.el-table .el-table__cell{
			border-bottom: 1px solid #EBEEF5;
		}
	} 
</style>